@page {
    size: A4;
    margin: 17mm 5mm 17mm 5mm;
}
/*@media (max-width: 900px){*/
@media print{
    .btn{
        display: none;
    }
    #page-wrapper{
        margin-top:-130px;
    }
}