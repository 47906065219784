/*!
 * Start Bootstrap - SB Admin Bootstrap Admin Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Global Styles */

body {
    margin-top: 100px;
    background-color: #222;
}

@media(min-width:768px) {
    body {
        margin-top: 50px;
    }
}

#wrapper {
    padding-left: 0;
}

#page-wrapper {
    width: 100%;
    padding: 0;
    background-color: #fff;
}

.huge {
    font-size: 50px;
    line-height: normal;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 225px;
    }

    #page-wrapper {
        padding: 60px 10px 10px;
    }
}

/* Top Navigation */

.top-nav {
    padding: 0 15px;
}

.top-nav>li {
    display: inline-block;
    float: left;
}

.top-nav>li>a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
    color: #999;
}

.top-nav>li>a:hover,
.top-nav>li>a:focus,
.top-nav>.open>a,
.top-nav>.open>a:hover,
.top-nav>.open>a:focus {
    color: #fff;
    background-color: #000;
}

.top-nav>.open>.dropdown-menu {
    float: left;
    position: absolute;
    margin-top: 0;
    border: 1px solid rgba(0,0,0,.15);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.top-nav>.open>.dropdown-menu>li>a {
    white-space: normal;
}

ul.message-dropdown {
    padding: 0;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
}

li.message-preview {
    width: 275px;
    border-bottom: 1px solid rgba(0,0,0,.15);
}

li.message-preview>a {
    padding-top: 15px;
    padding-bottom: 15px;
}

li.message-footer {
    margin: 5px 0;
}

ul.alert-dropdown {
    width: 200px;
}

/* Side Navigation */

@media(min-width:768px) {
    .side-nav {
        position: fixed;
        top: 51px;
        /*left: 225px;*/
        width: 225px;
        margin-left: -225px;
        border: none;
        border-radius: 0;
        overflow-y: auto;
        background-color: #222;
        bottom: 0;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    .side-nav>li>a {
        width: 225px;
    }

    .side-nav li a:hover,
    .side-nav li a:focus {
        outline: none;
        background-color: #000 !important;
    }
}

.side-nav>li>ul {
    padding: 0;
}

.side-nav>li>ul>li>a {
    display: block;
    padding: 10px 15px 10px 38px;
    text-decoration: none;
    color: #999;
}

.side-nav>li>ul>li>a:hover {
    color: #fff;
}

/* Flot Chart Containers */

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

/* Custom Colored Panels */

.huge {
    font-size: 40px;
}

.panel-primary {
    border-color: none;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green > .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green > a {
    color: #5cb85c;
}

.panel-green > a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red > .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red > a {
    color: #d9534f;
}

.panel-red > a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow > .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow > a {
    color: #f0ad4e;
}

.panel-yellow > a:hover {
    color: #df8a13;
}

.panel-pink {
    border-color: #d950d6;
}

.panel-pink > .panel-heading {
    border-color: #d950d6;
    color: #fff;
    background-color: #d950d6;
}

.panel-pink > a {
    color: #d950d6;
}

.panel-pink > a:hover {
    color: #a73da5;
}

.help-block{
    color: #a94442;
}
.top-buffer {
    margin-top:20px;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle){
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.table-hover > tbody > tr > td:last-child{
    border-left:0px solid !important;
    text-align: center;
}


.table-actions > tbody > tr > td:nth-last-child(2), .table-actions-view > tbody > tr > td:nth-last-child(3){
    border-right: none !important;
    border-left: none !important;
    text-align: center;
}
.table-action-fields > tbody > tr > td:nth-last-child(4){
    border-right: none !important;
    border-left: none !important;
    text-align: center;
}
.table-action-fields > tbody > tr > td:nth-last-child(5){
    border-right: none !important;
    border-left: none !important;
    text-align: center;
}
.label-none{
    color: #333;
}
.select2-selection--single{
    border: 1px solid #ccc;
    padding: 6px 12px;
    border-radius: 4px;
    height: 34px !important;
}
.select2-container {
    padding: 0px;
    border-width: 0px;
}
.select2-container .select2-choice {
    height: 38px;
    line-height: 38px;
}
.table-actions > thead:first-child > tr:first-child > th:last-child{
    text-align: center;
}
.col-lg-4 h3{
    display: inline;
}
.form-control[readonly]{
    background-color: #f9f9f9;
    border:none;
}
.stylish {
    font-family: georgia, FontAwesome;
}


.table-id th:first-child{
    text-align: center !important;
}

.page-header a.btn, .page-header button{
    margin-left:20px;
}
.edit-comment{
    display: none;
}
.comment-actions{
    margin-top: -2px;
    float: right;
    display: none;
}
.panel-body:hover .comment-actions{
    display : block;
}
.table th.center{
    text-align: center;
}
.no-right-radius + span .select2-selection{
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0  !important;
}
#createContact{
    /*display: none;*/
}
.inner-form{
    padding: 25px;
    background-color: #f9f9f9;
}
.center{
    text-align: center;
}
.right-buffer{
    margin-right: 20px;
}
#createContact, #form-errors{
    display: none;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple{
    background-color: #f9f9f9;
    border: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.select2-container--default .select2-selection--multiple,.select2-container--default.select2-container--focus .select2-selection--multiple{
    border: 1px solid #ccc;
}

th a{
    color: inherit;
    padding-right: 5px;
}
th a:hover{
    color: #337ab7;
    text-decoration: none;
}
.filter-box {
    display: inline-block;
}
.custom-search-form{
    width: 220px;
}
.inline{
    display: inline-block;
}

.sub-nav .btn-group
{
    margin-left:20px;
    vertical-align: baseline;
}
.nav-btn .btn{
    float: left;
    margin-left:20px;
    max-width: 120px;
}
.flexbox{
    display: inline-flex;
}
textarea.smaller-box{
    height: 100px !important;
}
.btn-submissions{
    color: #fff;
    background-color: #656565;
    border-color: #5A5A5A;
}
.btn-submissions:hover {
    color: #fff;
    background-color: #555555;
    border-color: #555555;
}
.polyfill-date{
    display: inline-block;
    margin-left:10px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.btr-dateinput-value{
    margin-left:35px;
}
#end_date{
    margin-left: 14px;
}
a{
    cursor: pointer;
    text-decoration: none;
}
.page-header {
    overflow: auto;
}
.navbar-inverse .navbar-nav > .active > ul > .active > a {
    color: #fff;
    background-color: #090909;
}
#submissionStatesFilter {
    min-width: 100px;
}
#previewBox{
    display: none;
}
#previewBox .form-group{
    text-align: center;
}
#previewBox > .form-group > label{
    text-transform: uppercase;
}

#previewField{
    background-color: whitesmoke;
    padding: 20px;
    text-align: left !important;
}
.fieldImg{
    width: 100px;
    height: 100px;
    margin-left: 15px;
}